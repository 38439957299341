import React, { useEffect, useReducer, useState } from 'react';

import { Button, Col, Input, Row, Select } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { ApartmentInterface } from 'components/_Common/_Interfaces/Apartment';
import API from 'lib/API';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { useVehicle } from 'lib/providers/VehicleContextProvider';
import type { OptionalField } from 'types/packages/antd';
import { Vehicle } from 'types/Vehicle';


type Filter = {
    apartmentId: OptionalField<ApartmentInterface['id']>,
    type: OptionalField<Vehicle.Model['type']>,
    brand: OptionalField<Vehicle.Model['brand']>,
    model: OptionalField<Vehicle.Model['model']>,
    board: OptionalField<Vehicle.Model['board']>,
    tag: OptionalField<Vehicle.Model['tag']>,
}

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    apartmentId: undefined,
    type: undefined,
    brand: undefined,
    model: undefined,
    board: undefined,
    tag: undefined,
};

const filterOption = makeFilterOption();

export function VehiclesFilterBar() {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);
    const [apartments, setApartments] = useState<ApartmentInterface[]>([]);

    const [isFiltering, setIsFiltering] = useState(false);

    const { 
        vehicles,
        setVehicles,
        fetchVehicles,
    } = useVehicle();

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchVehicles();
    };

    const fetchOptions = async () => {
        const result = await API.get('/apartment');
        setApartments(result);
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const handleFilter = () => {
        let filteredVehicles = vehicles;

        const { 
            apartmentId,
            type,
            brand,
            model,
            board,
            tag,
        } = filter;

        if (isFilledField<Vehicle.Model['apartmentId']>(apartmentId)) 
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.apartmentId === apartmentId);

        if (isFilledField<Vehicle.Model['type']>(type))
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.type.toLowerCase().includes(type.toLowerCase()));

        if (isFilledField<Vehicle.Model['brand']>(brand))
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.brand === brand);

        if (isFilledField<Vehicle.Model['model']>(model))
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.model === model);

        if (isFilledField<Vehicle.Model['board']>(board))
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.board?.includes(board));

        if (isFilledField<Vehicle.Model['tag']>(tag))
            filteredVehicles = filteredVehicles.filter(vehicle => vehicle.tag?.includes(tag));

        setIsFiltering(true);
        setVehicles(filteredVehicles);
    };

    /** Disable the filter action when all filters are empty */
    const isActionButtonDisabled = Object.values(filter).every(value => value === undefined);

    const parsedTypes = [
        { value: 'car', label: 'Carro' },
        { value: 'motorbike', label: 'Moto' },
        { value: 'other', label: 'Outros' },

    ];
    
    return (
        <Row gutter={[8, 8]}>

            <Col md={6} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    disabled={isFiltering}
                    loading={apartments.length <= 0}
                    showSearch
                    value={filter.apartmentId}
                    onChange={(value) => setFilter({ apartmentId: value })}
                    placeholder="Unidades"
                    allowClear
                    filterOption={(input, option: any) => {
                        const searchTerms = input.toLowerCase().split(/[\s-]+/);
                        const apartmentParts = option.children.toLowerCase().split(/[\s-]+/);
                
                        return searchTerms.every((term: string) =>
                            apartmentParts.some((part: string) => part.includes(term))
                        );
                    }}
                >
                    {apartments.map((apartment: ApartmentInterface) => (
                        <Select.Option key={apartment.id} value={apartment.id}>
                            {`${apartment.tower?.name || 'Sem Torre'} - ${apartment.name}`}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedTypes}
                    value={filter.type}
                    onChange={value => setFilter({ type: value })}
                    onClear={() => setFilter({ type: undefined })}
                    placeholder="Tipo"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Input
                    value={filter.board ?? undefined}
                    onChange={event => setFilter({ board: event.currentTarget.value })}
                    placeholder="Placa"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={4} xs={24}>
                <Input
                    value={filter.tag ?? undefined}
                    onChange={event => setFilter({ tag: event.currentTarget.value })}
                    placeholder="Tag"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={6} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
