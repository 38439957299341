import React, { useState } from 'react';

import { App, Button, Dropdown, MenuProps, Space } from 'antd';
import { useAppProps } from 'antd/es/app/context';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteConstruction } from 'services/Construction.service';

type Props = { construction: Construction.Model };

const makeItems = (
    construction: Props['construction'],
    app: useAppProps,
    handleDelete: () => void,
    handleEdit: () => void,
): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    items.push(
        {
            key: 'edit',
            label: 'Editar',
            icon: <EditOutlined />,
            onClick: handleEdit,
        },
        {
            key: 'delete',
            label: 'Excluir',
            icon: <DeleteOutlined />,
            onClick: () => app.modal.confirm({
                title: 'Excluir obra',
                content: 'Tem certeza que deseja excluir esta obra?',
                okText: 'Sim',
                okType: 'danger',
                onOk: handleDelete,
                cancelText: 'Não',
            }),
            danger: true,
        }
    );
    
    return items;
};

export function ConstructionActionsCell({ construction }: Props) {
    const [isSending, setIsSending] = useState(false);

    const {
        setIsDetailModalVisible,
        setConstructionId,
        setIsEditModalVisible,
        fetchConstructions,
    } = useConstruction();

    const app = App.useApp();
    
    const handleDetail = () => {
        setConstructionId(construction.id);
        setIsDetailModalVisible(true);
    };

    const handleEdit = () => {
        setConstructionId(construction.id);
        setIsEditModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteConstruction(construction.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: 'Sucesso',
            description: response.message,
        });

        fetchConstructions();
    };

    if (!Authorization.hasAccess(FEATURE['CONSTRUCTION::WRITE']))
        return (
            <Button
                onClick={handleDetail}
                loading={isSending}
            >
                Detalhes
            </Button>
        );

    const items = makeItems(
        construction,
        app,
        handleDelete,
        handleEdit
    );

    return (
        <Space size="middle">
            <Dropdown.Button
                trigger={['click']}
                menu={{ items, disabled: isSending }}
                onClick={handleDetail}
                loading={isSending}
            >
                Detalhes
            </Dropdown.Button>
        </Space>
    );
}
