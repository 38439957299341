import React, { useState } from 'react';

import { App, Divider, Form, Modal, Select, SelectProps, Typography } from 'antd';

import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useGeneralMeeting } from 'lib/providers/GeneralMeetingContextProvider';
import { CreateGeneralMeeting } from 'services/contracts/GeneralMeeting.contract';
import { createGeneralMeeting } from 'services/GeneralMeeting.service';

type Values = { client: Client.Model['id'] };

type Options = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function DuplicateGeneralMeeting() {
    const [isSending, setIsSending] = useState(false);

    const { 
        setIsDuplicateModalVisible,
        generalMeeting, 
        fetchGeneralMeetings,
        setGeneralMeetingId,
    } = useGeneralMeeting();

    if (!generalMeeting)
        throw new Error('Value of the `generalMeeting` property in the `useGeneralMeeting` custom hook is unknown');

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => {
        setIsDuplicateModalVisible(false);
        setGeneralMeetingId(null);
    };

    const onFinish = async ({ client }: Values) => {
        setIsSending(true);

        const { 
            title,
            description,
            closing_date,
            questions,
        } = generalMeeting;

        const body: CreateGeneralMeeting.Body = {
            title,
            description,
            closing_date,
            questions,
            client_id: client,
        };

        const response = await createGeneralMeeting(body);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchGeneralMeetings();
    };
    
    const initialValues: Values = { client: generalMeeting.client_id };

    const parsedClients: Options = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Modal
            title="Duplicar enquete"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Duplicar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Typography.Paragraph>
                Ao continuar, você estará duplicando a enquete <strong>{generalMeeting.title}</strong> para o condomínio selecionado abaixo. Caso você tenha acesso a mais de um condomínio, será possível escolher para qual condomínio você deseja realizar a duplicação.
            </Typography.Paragraph>

            <Typography.Paragraph italic>
                Não se preocupe. As respostas da enquete original não serão levadas em consideração para a enquete duplicada.
            </Typography.Paragraph>

            <Form
                form={form}
                onFinish={onFinish}
                name="duplicateGeneralMeeting"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Form.Item<Values>
                    name="client"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select
                        options={parsedClients}
                        filterOption={filterOption}
                        disabled={parsedClients.length === 1}
                        showSearch
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
