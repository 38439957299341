import React from 'react';

import { Badge, Button, Calendar, notification, Row, Space, Spin, Typography } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';

import API from '../../lib/API';
import { Show } from '../../lib/Show';
import { listAmbients } from '../../services/Ambient.service';
import { ApartmentInterface } from '../_Common/_Interfaces/Apartment';
import { ReserveInterface } from '../_Common/_Interfaces/Reserve';
import Content from '../_Common/_Layout/Content';
import { CurrentBookingDateReservesModal } from './CurrentBookingDateReserves';
import { prepareToDownload } from './Excel';
import FormReserve from './FormReserve';
import { PendingReservesModal } from './PendingReservesModal';

type State = {
    bookingDate: dayjs.Dayjs | null,
    apartments: Array<ApartmentInterface>;
    ambients: Ambient.SimpleModel[];
    reserves: Reserve.Model[];
    dateSelected: Dayjs,
    loading: boolean;
    isVisible: boolean;
    isPendingReservesModalVisible: boolean,
    isCurrentBookingDateReservesModalVisible: boolean,
};

export default class ReserveIndex extends React.Component<any, State> {
    state: State = {
        bookingDate: null,
        apartments: [],
        ambients: [],
        reserves: [],
        dateSelected: dayjs(),
        isVisible: false,
        loading: true,
        isPendingReservesModalVisible: false,
        isCurrentBookingDateReservesModalVisible: false,
    };

    fetchReserves = async () => {
        const { reserves } = await API.get('/reserve');

        this.setState({ reserves });
    };

    fetcData = async () => {
        const response = await listAmbients();

        if (!response.success)
            return notification.error({ message: 'Erro', description: response.message ?? 'Ocorreu um problema ao carregar os ambientes' });

        const { ambients } = response;

        const { reserves } = await API.get('/reserve');

        this.setState({
            apartments: await API.get('/apartment'),
            reserves,
            ambients,
            loading: false
        });
    };

    getReservesByBookingDate = (bookingDate: Dayjs): any[] => {
        const { reserves } = this.state;

        const approvedReserves = reserves.filter(({ approved }) => approved === true);

        const filteredReserves = approvedReserves.filter(reserve => dayjs(reserve.bookingDate).isSame(bookingDate, 'day'));

        return filteredReserves;
    };

    onSelectDate = async (bookingDate: dayjs.Dayjs) => {
        if (!dayjs(bookingDate).isSame(new Date(), 'day') && dayjs(bookingDate).isBefore(new Date()))
            return notification.warning({ message: 'Atenção', description: 'Não é possível realizar um agendamento nesta data.' });

        const reserves = this.getReservesByBookingDate(bookingDate);

        if (reserves.length > 0)
            return this.setState({ isCurrentBookingDateReservesModalVisible: true, bookingDate });

        this.setState({ isVisible: true, bookingDate });
    };

    dateCellRender = (value: dayjs.Dayjs) => {
        const reserves = this.getReservesByBookingDate(value);

        return reserves.map(({ id, apartment, ambient }) => (
            <Typography.Text code key={id}>
                {apartment.tower.name}
                {' '}
                {apartment.name}
                {' | '}
                {ambient.deleted_at !== null ? <s>{ambient.name}</s> : ambient.name}
            </Typography.Text>
        ));
    };

    handleDownload = () => {
        prepareToDownload(this.state.reserves.filter(reserve => dayjs(reserve.bookingDate).isSame(this.state.dateSelected, 'month')));
    };


    componentDidMount = () => {
        this.fetcData();
    };

    render() {
        const {
            loading,
            reserves,
            isVisible,
            apartments,
            ambients,
            bookingDate,
            isPendingReservesModalVisible,
            isCurrentBookingDateReservesModalVisible,
        } = this.state;

        const pendingReserves = reserves.filter(reserve => reserve.approved === null && dayjs(reserve.bookingDate).isAfter(dayjs().subtract(1, 'day')));

        const currentBookingDateReserves = bookingDate !== null
            ? this.getReservesByBookingDate(bookingDate)
            : null;

        return (
            <Content>
                <Row justify="space-between" align="middle">
                    <Typography.Title level={3}>
                        Agendamentos
                    </Typography.Title>
                    <Space>
                        <Button
                            size='middle'
                            type='primary'
                            icon={<CloudDownloadOutlined />}
                            onClick={this.handleDownload} block>
                            Baixar relatório
                        </Button>
                        <Show when={Authorization.hasAccess(FEATURE['RESERVE::READ_PENDING_RESERVES'])}>
                            <Badge color="orange" count={pendingReserves.length}>
                                <Button type="primary" onClick={() => this.setState({ isPendingReservesModalVisible: true })}>
                                    Reservas pendentes
                                </Button>
                            </Badge>
                        </Show>
                    </Space>
                </Row>

                {loading
                    ? (
                        <Row className='center'>
                            <Spin style={{ justifySelf: 'center' }} tip="Carregando..." size='large' />
                        </Row>
                    ) : (
                        <Calendar
                            dateCellRender={this.dateCellRender}
                            onSelect={this.onSelectDate}
                            onPanelChange={(date) => this.setState({ dateSelected: date })}
                        />
                    )
                }

                <FormReserve
                    bookingDate={bookingDate}
                    reserve={{} as ReserveInterface}
                    apartments={apartments}
                    ambients={ambients}
                    isVisible={isVisible}
                    hideModalForm={() => { this.setState({ isVisible: false }, () => this.fetchReserves()); }}
                />

                <Show when={isCurrentBookingDateReservesModalVisible}>
                    <CurrentBookingDateReservesModal
                        bookingDate={bookingDate}
                        currentBookingDateReserves={currentBookingDateReserves}
                        handleClose={() => this.setState({ isCurrentBookingDateReservesModalVisible: false, bookingDate: null })}
                        openNewReserveModal={() => this.setState({ isVisible: true })}
                        fetchReserves={this.fetchReserves}
                    />
                </Show>

                <Show when={isPendingReservesModalVisible}>
                    <PendingReservesModal
                        onCancel={() => { this.setState({ isPendingReservesModalVisible: false }, () => this.fetchReserves()); }}
                    />
                </Show>
            </Content>
        );
    }
}