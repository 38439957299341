import React, { useState } from 'react';

import { App, Button, Dropdown, MenuProps, Space } from 'antd';
import { useAppProps } from 'antd/es/app/context';

import { DeleteOutlined } from '@ant-design/icons';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { LoadedEmployee, useEmployee } from 'lib/providers/EmployeeContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteEmployee } from 'services/Employee.service';

type Props = { employee: LoadedEmployee };

const makeItems = (
    employee: Props['employee'],
    app: useAppProps,
    handleDelete: () => void,
): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    items.push(
        {
            key: 'delete',
            label: 'Excluir',
            icon: <DeleteOutlined />,
            onClick: () => app.modal.confirm({
                title: 'Excluir funcionário',
                content: 'Tem certeza que deseja excluir este funcionário?',
                okText: 'Sim',
                okType: 'danger',
                onOk: handleDelete,
                cancelText: 'Não',
            }),
            danger: true,
        }
    );
    
    return items;
};

export function EmployeeActionsCell({ employee }: Props) {
    const [isSending, setIsSending] = useState(false);

    const {
        setIsDetailModalVisible,
        setEmployeeId,
        fetchEmployees,
    } = useEmployee();

    const app = App.useApp();
    
    const handleDetail = () => {
        setEmployeeId(employee.id);
        setIsDetailModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteEmployee(employee.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: 'Sucesso',
            description: response.message,
        });

        fetchEmployees();
    };

    if (!Authorization.hasAccess(FEATURE['EMPLOYEE::WRITE']))
        return (
            <Button
                onClick={handleDetail}
                loading={isSending}
            >
                Detalhes
            </Button>
        );

    const items = makeItems(
        employee,
        app,
        handleDelete,
    );

    return (
        <Space size="middle">
            <Dropdown.Button
                trigger={['click']}
                menu={{ items, disabled: isSending }}
                onClick={handleDetail}
                loading={isSending}
            >
                Detalhes
            </Dropdown.Button>
        </Space>
    );
}
