import React from 'react';

import { TableColumnsType, Tag } from 'antd';

import { EmployeeActionsCell } from 'components/Employee/EmployeeActionsCell';
import dayjs from 'dayjs';
import { getEmployeeStatus, getVacationTimeStatus } from 'lib/helpers/Employe.helper';
import { LoadedEmployee } from 'lib/providers/EmployeeContextProvider';

const calculateUsedVacationDays = (vacation_times: any): number => {
    let totalUsed = 0;

    Object.keys(vacation_times).forEach((key) => {
        if (key.includes('_start') && vacation_times[key]) {
            const start = dayjs(vacation_times[key]);
            const endKey = key.replace('_start', '_end');
            const end = vacation_times[endKey] ? dayjs(vacation_times[endKey]) : null;

            if (start && end) {
                totalUsed += end.diff(start, 'days') + 1;
            }
        }
    });

    return totalUsed;
};

const calculateLimitDay = (
    usedDays: number,
    periodEnd: string | null
): string | null => {

    if (usedDays >= 30 || !periodEnd)
        return null;

    const daysLeft = 30 - usedDays;
    const limit = dayjs(periodEnd).subtract(daysLeft, 'day');
    return limit.isValid() ? limit.format('DD/MM/YYYY') : null;
};

export const Columns: TableColumnsType<LoadedEmployee> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName',
    },
    {
        title: 'Funcionário',
        dataIndex: 'name',
    },
    {
        title: 'Status',
        key: 'status',
        render: (_, employee) => {
            const employeeProps = getEmployeeStatus(
                employee,
                employee.vacation_time_acquireds?.[0]?.vacation_times
            );
            return <Tag {...employeeProps} />;
        },
        width: 180,
    },
    {
        title: 'Status das férias',
        key: 'vacationTime.status',
        render: (_, employee) => {
            const vacationProps = getVacationTimeStatus(
                employee.vacation_time_acquireds?.[0]
            );
            return <Tag {...vacationProps} />;
        },
        width: 180,
    },
    {
        title: 'Data de início',
        key: 'start_date',
        render: (_, employee) => {
            const vacationTimeAcquired = employee.vacation_time_acquireds?.[0];
            return vacationTimeAcquired?.start_date
                ? dayjs(vacationTimeAcquired.start_date).format('DD/MM/YYYY')
                : '-';
        },
        width: 135,
    },
    {
        title: 'Data limite',
        key: 'limit_date',
        render: (_, employee) => {
            const vacationTimeAcquired = employee.vacation_time_acquireds?.[0];
            if (vacationTimeAcquired) {
                const usedDays = calculateUsedVacationDays(vacationTimeAcquired.vacation_times);
                const limitDate = calculateLimitDay(usedDays, vacationTimeAcquired.end_date);
                return limitDate || '-';
            }
            return '-';
        },
        width: 130,
    },
    {
        title: 'Data final',
        key: 'end_date',
        render: (_, employee) => {
            const vacationTimeAcquired = employee.vacation_time_acquireds?.[0];
            return vacationTimeAcquired?.end_date
                ? dayjs(vacationTimeAcquired.end_date).format('DD/MM/YYYY')
                : '-';
        },
        width: 130,
    },
    {
        title: 'Dias Restantes',
        key: 'days_left',
        render: (_, employee) => {
            const vacationTimeAcquired = employee.vacation_time_acquireds?.[0];
            if (vacationTimeAcquired?.vacation_times) {
                const usedDays = calculateUsedVacationDays(vacationTimeAcquired.vacation_times);
                const daysLeft = Math.max(0, 30 - usedDays);
                return daysLeft;
            }
            return '-';
        },
        width: 130,
    },
    {
        width: '100px',
        align: 'center',
        render: (_, employee) => <EmployeeActionsCell employee={employee} />,
    },
];
