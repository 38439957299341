import React, { useEffect, useState } from 'react';

import { 
    App,
    Divider,
    Form,
    Input,
    Modal,
    Select,
} from 'antd';

import { ApartmentInterface } from 'components/_Common/_Interfaces/Apartment';
import { ResidentInterface } from 'components/_Common/_Interfaces/Resident';
import API from 'lib/API';
import { useVehicle } from 'lib/providers/VehicleContextProvider';
import { sleep } from 'lib/Sleep';
import { createVehicle } from 'services/Vehicle.service';
import { Vehicle } from 'types/Vehicle';

export type Values = {
    residentId: Vehicle.Model['residentId'];
    apartmentId: Vehicle.Model['apartmentId'],
    type: Vehicle.Model['type'],
    brand: Vehicle.Model['brand'],
    model: Vehicle.Model['model'],
    board: Vehicle.Model['board'],
    tag: Vehicle.Model['tag'],
};

export function CreateVehicleModal() {
    const [isSending, setIsSending] = useState(false);
    const [apartments, setApartments] = useState<ApartmentInterface[]>([]);
    const [filteredResidents, setFilteredResidents] = useState<ResidentInterface[]>([]);

    const [residents, setResidents] = useState<ResidentInterface[]>([]);
    const { setIsCreateModalVisible, fetchVehicles} = useVehicle();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => setIsCreateModalVisible(false);

    const fetchOptions = async () => {
        const resultApartment = await API.get('/apartment');
        const resultResident = await API.get('/resident');
        setApartments(resultApartment);
        setResidents(resultResident);
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const handleApartmentChange = (apartmentId: string) => {
        form.setFieldsValue({ residentId: undefined });

        const selectedApartment = apartments.find((apartment) => apartment.id === Number(apartmentId));
        if (selectedApartment) {
            const filtered = residents.filter(
                (resident) => resident.apartmentId === selectedApartment.id
            );
            setFilteredResidents(filtered);
        } else {
            setFilteredResidents([]);
        }
    };

    const onFinish = async ({
        residentId,
        apartmentId,
        type,
        brand,
        model,
        board,
        tag,}: Values) => {
        setIsSending(true);

        const response = await createVehicle({
            residentId,
            apartmentId,
            type,
            brand,
            model,
            board,
            tag,
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        fetchVehicles();
        close();
    };

    return (
        <Modal
            title="Novo Veículo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createVehicle"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="apartmentId"
                    label="Apartamento"
                    rules={[{ required: true, message: 'Por favor, selecione um apartamento.' }]}
                >
                    <Select
                        disabled={apartments.length <= 0}
                        loading={apartments.length <= 0}
                        showSearch
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleApartmentChange}
                    >
                        {apartments.map((apartment: ApartmentInterface) => (
                            <Select.Option key={apartment.id} value={apartment.id}>
                                {`${apartment.tower.name} - ${apartment.name}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="residentId"
                    label="Proprietário"
                    rules={[{ required: true, message: 'Por favor, selecione o Proprietário.' }]}
                >
                    <Select
                        disabled={filteredResidents.length <= 0}
                        loading={filteredResidents.length <= 0}
                        showSearch
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {filteredResidents.map((resident: ResidentInterface) => (
                            <Select.Option key={resident.id} value={resident.id.toString()}>
                                {`${resident.name}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Tipo"
                    rules={[{ required: true, message: 'Por favor, digite um tipo.' }]}
                >
                    <Select>
                        <Select.Option value='car'>Carro</Select.Option>
                        <Select.Option value='motobike'>Moto</Select.Option>
                        <Select.Option value='other'>Outros</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="brand"
                    label="Marca"
                    rules={[{ required: true, message: 'Por favor, digite uma marca.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="model"
                    label="Modelo"
                    rules={[{ required: true, message: 'Por favor, digite um modelo.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="board"
                    label="Placa"
                    rules={[{ required: true, message: 'Por favor, digite uma placa.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="tag"
                    label="tag"
                >
                    <Input maxLength={500} />
                </Form.Item>

            </Form>
        </Modal>
    );
}

