import React from 'react';

import { TableColumnsType } from 'antd';

import { VehicleActionsCell } from 'components/Vehicle/VehicleActionsCell';
import { Vehicle } from 'types/Vehicle';

export const Columns: TableColumnsType<Vehicle.With<'apartment' | 'resident'>> = [

    {
        title: 'Unidade',
        key: 'apartment',
        render: (_, vehicle: Vehicle.With<'apartment'>) => (
            `${vehicle.apartment?.tower?.name || ''} - ${vehicle.apartment?.name || ''}`
        ),
    },

    {
        title: 'Proprietário',
        key: 'resident',
        render: (_, vehicle: Vehicle.With<'resident'>) => (
            `${vehicle.resident?.name || ''}`
        ),
    },

    {
        title: 'Tipo',
        dataIndex: 'type',
        render: (type: string) => {
            if (type === 'car') return 'Carro';
            else if (type === 'motobike') return 'Moto';
            return 'Outro';
        },
    },

    {
        title: 'Marca',
        dataIndex: 'brand',
    },

    {
        title: 'Modelo',
        dataIndex: 'model',
    },

    {
        title: 'Placa',
        dataIndex: 'board',
    },

    {
        title: 'Tag',
        dataIndex: 'tag',
    },


    {
        width: '100px',
        align: 'center',
        render: (_, vehicle) => <VehicleActionsCell vehicle={vehicle} />,
    },
];