import React, { useEffect, useState } from 'react';

import { Alert, DatePicker, Divider, Form, InputNumber, Modal, notification, Select, Spin, UploadFile } from 'antd';

import dayjs from 'dayjs';
import { formatBRL } from 'lib/helpers/BRL';
import { UploadField } from 'lib/UploadField';

import { useConsumption } from '../../lib/hooks/useConsumption';
import { createConsumptionReading, findLastConsumptionReading, findLastConsumptionReadingByApartment } from '../../services/Consumption.service';

export type Values = {
    consumption_id: Consumption.Reading['consumption_id'],
    apartment_id: Consumption.Reading['apartment_id'],
    reading_date: Consumption.Reading['reading_date'],
    current_reading: Consumption.Reading['current_reading'],
    last_read: Consumption.Reading['last_read'] | null,
    picture: UploadFile[] | null,
};

export function ConsumptionReadingFormModal() {
    const [isSending, setIsSending] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [consumption, setConsumption] = useState<Consumption.Model>();
    const [lastConsumptionRead, setLastConsumptionReading] = useState<Consumption.Reading | null>();
    const [apartmentsFiltered, setApartmentFiltered] = useState<Apartment.Model[]>([]);

    const [form] = Form.useForm<Values>();
    const reading_date = Form.useWatch('reading_date', form);

    const { setIsConsumptionReadingFormModalVisible, fetchConsumptions, isFetching, consumptions, consumptionsReading, apartments } = useConsumption();

    const onFinish = async () => {
        setIsSending(true);
        const body = await form.validateFields();

        if (body.picture)
            body.picture = body.picture[0].response ?? body.picture[0].url;

        const response = await createConsumptionReading(body);
        await fetchConsumptions();

        if ('status' in response)
            return;

        setIsSending(false);
        notification.open(response);

        form.resetFields();
        form.setFieldsValue({
            reading_date: body.reading_date,
            consumption_id: body.consumption_id
        });
        setLastConsumptionReading(null);
    };

    const getLastConsumptionReading = async (consumption_id: Consumption.Model['id']) => {
        const response = await findLastConsumptionReading(consumption_id);

        if (response != null && 'status' in response)
            return;

        setLastConsumptionReading(response);
    };

    const getLastConsumptionReadingByApartment = async (apartment_id: Apartment.Model['id']) => {
        if (consumption) {
            const response = await findLastConsumptionReadingByApartment(consumption?.type_consumption_id, apartment_id);

            if (response != null && 'status' in response)
                return;

            setLastConsumptionReading(response);
        }
    };

    const onChangeApartment = async (value: number) => {
        setIsSearching(true);
        setLastConsumptionReading(undefined);
        await getLastConsumptionReadingByApartment(value);
        setIsSearching(false);
    };

    const onChangeConsumption = async (value: number) => {
        setIsSearching(true);
        setLastConsumptionReading(undefined);

        const consumptionSelected = consumptions.find(c => c.id === value);
        setConsumption(consumptionSelected);

        if (consumptionSelected?.measure === 'aggregate')
            await getLastConsumptionReading(consumptionSelected.id);

        setIsSearching(false);
    };

    const filterApartments = () => {
        form.setFieldValue('apartment_id', undefined);

        const result = apartments.filter(apartment => {
            if (consumptionsReading.find(reading => reading.apartment_id === apartment.id
                && reading.consumption_id === consumption?.id
                && dayjs(reading.reading_date).format('YYYY-MM-DD') === dayjs(form.getFieldValue('reading_date')).format('YYYY-MM-DD'))
            )
                return null;

            return apartment;
        });

        setApartmentFiltered(result);
    };

    useEffect(() => {
        form.setFieldValue('reading_date', dayjs());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filterApartments();
    }, [consumption, consumptionsReading, reading_date]);

    return (
        <Modal
            open
            title="Cadastrar leitura de consumo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={() => setIsConsumptionReadingFormModalVisible(false)}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createConsumption"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="consumption_id"
                    label="Tipo de consumo"
                    rules={[{ required: true, message: 'Por favor, selecione o tipo de consumo que deseja definir.' }]}
                >
                    <Select
                        disabled={isFetching}
                        loading={isFetching}
                        onChange={onChangeConsumption}>
                        {
                            consumptions.map(consumption =>
                                <Select.Option value={consumption.id} >
                                    {`${consumption.type_consumption.name} - Fornecedor ${consumption.supplier.name} (${formatBRL(consumption.price)})`}
                                </Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="reading_date"
                    label="Data da leitura"
                    rules={[{ required: true, message: 'Por favor, informe a data de leitura.' }]}
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>
                {
                    consumption?.measure === 'unit'
                        ? <Form.Item
                            name="apartment_id"
                            label="Unidade"
                            rules={[{ required: true, message: 'Por favor, selecione a unidade.' }]}
                        >
                            <Select
                                disabled={isFetching}
                                loading={isFetching}
                                showSearch
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={onChangeApartment}
                            >
                                {
                                    apartmentsFiltered.map(apartment =>
                                        <Select.Option value={apartment.id}>
                                            {`${apartment.tower.name} ${apartment.name}`}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        : consumption?.measure === 'aggregate'
                            ? <Form.Item>
                                <Alert
                                    type='info'
                                    showIcon
                                    message='O consumo selecionado é medido de forma coletiva.'
                                />
                            </Form.Item>
                            : null
                }
                {
                    lastConsumptionRead
                        ? <Form.Item>
                            <Alert
                                type='success'
                                showIcon
                                message='Identificamos a última leitura e realizamos o preenchimento de forma automática.'
                            />
                        </Form.Item>
                        : lastConsumptionRead === null
                            ? <Form.Item>
                                <Alert
                                    type='info'
                                    showIcon
                                    message='O condomínio ainda não possui leituras registradas.'
                                />
                            </Form.Item>
                            : null
                }
                {
                    isSearching
                        ? <Spin />
                        : <>
                            {
                                lastConsumptionRead === null || lastConsumptionRead === undefined
                                    ? <Form.Item
                                        name="last_read"
                                        label="Última leitura"
                                        rules={[{ required: true, message: 'Por favor, informe o valor da última leitura.' }]}
                                    >
                                        <InputNumber
                                            addonBefore={consumption?.type_consumption.unit_of_measurement}
                                            decimalSeparator=","
                                            precision={3}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    : <Form.Item
                                        label="Última leitura"
                                        rules={[{ required: true, message: 'Por favor, informe o valor da última leitura.' }]}
                                    >
                                        <InputNumber
                                            disabled
                                            defaultValue={lastConsumptionRead.current_reading}
                                            addonBefore={consumption?.type_consumption.unit_of_measurement}
                                            decimalSeparator=","
                                            precision={3}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                            }
                            <Form.Item
                                name="current_reading"
                                label="Leitura atual"
                                rules={[{ required: true, message: 'Por favor, informe o valor da leitura atual.' }]}
                            >
                                <InputNumber
                                    addonBefore={consumption?.type_consumption.unit_of_measurement}
                                    decimalSeparator=","
                                    precision={3}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <UploadField name='picture' type='picture' maxCount={1} multiple={false} />
                        </>
                }
            </Form>
        </Modal>
    );
}
