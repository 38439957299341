import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { formatBRL } from 'lib/helpers/BRL';
import * as XLSX from 'xlsx';

export function ConsumptionReport(consumptionsReading: Consumption.Reading[]) {
    const client = Authentication.getClients().find(c => c.id === Authentication.getCurrentClientId());

    const data = consumptionsReading
        .filter(reading => dayjs(reading.reading_date).format('MM-YYYY') === dayjs().format('MM-YYYY'))
        .map(reading => (
            {
                'Consumo': reading.consumption.type_consumption.name,
                'Unidade': reading.apartment
                    ? `${reading.apartment.tower.name} - ${reading.apartment.name}`
                    : 'Coletivo',
                'Data da leitura': dayjs(reading.reading_date).format('DD/MM/YYYY'),
                'Última leitura': reading.last_read,
                'Leitura atual': reading.current_reading,
                'Consumo do mês': reading.monthly_consumption,
                'Valor do consumo': formatBRL(reading.monthly_consumption * reading.consumption.price)
            }
        ));

    const workbook = XLSX.utils.book_new();

    const worksheetClosed = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheetClosed, 'Leituras');

    const excelBase64 = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelBase64;
    link.download = `${client?.name} - Relatório de leituras.xlsx`;
    link.target = '_blank';

    return link.click();
}