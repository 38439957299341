import React, { useEffect, useState } from 'react';

import {
    App,
    Divider,
    Form,
    Input,
    Modal,
    Select,
} from 'antd';

import { ApartmentInterface } from 'components/_Common/_Interfaces/Apartment';
import { ResidentInterface } from 'components/_Common/_Interfaces/Resident';
import API from 'lib/API';
import { useVehicle } from 'lib/providers/VehicleContextProvider';
import { sleep } from 'lib/Sleep';
import { updateVehicle } from 'services/Vehicle.service';
import { Vehicle } from 'types/Vehicle';

export type Values = {
    residentId: Vehicle.Model['residentId'];
    apartmentId: Vehicle.Model['apartmentId'],
    type: Vehicle.Model['type'],
    brand: Vehicle.Model['brand'],
    model: Vehicle.Model['model'],
    board: Vehicle.Model['board'],
    tag: Vehicle.Model['tag'],
};

export function EditVehicleModal() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [apartments, setApartments] = useState<ApartmentInterface[]>([]);
    const [filteredResidents, setFilteredResidents] = useState<ResidentInterface[]>([]);
    const [residents, setResidents] = useState<ResidentInterface[]>([]);
    const [selectedApartmentId, setSelectedApartmentId] = useState<Apartment.Model['id'] | undefined>();


    const fetchOptions = async () => {
        setIsLoading(true);

        const resultApartment = await API.get('/apartment');
        const resultResident = await API.get('/resident');
        setApartments(resultApartment);
        setResidents(resultResident);

        setIsLoading(false);
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const {
        vehicle,
        setIsEditModalVisible,
        setVehicleId,
        fetchVehicles,
    } = useVehicle();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    useEffect(() => {
        if (vehicle) {
            const {
                residentId,
                apartmentId,
                type,
                brand,
                model,
                board,
                tag,
            } = vehicle;

            setSelectedApartmentId(apartmentId);

            form.setFieldsValue({
                residentId,
                apartmentId,
                type,
                brand,
                model,
                board,
                tag,
            });
        }
    }, [vehicle, form]);

    useEffect(() => {
        const filtered = residents.filter((resident) => resident.apartmentId === selectedApartmentId);
        setFilteredResidents(filtered);
    }, [selectedApartmentId, apartments, residents]);

    const close = () => {
        setIsEditModalVisible(false);
        setVehicleId(null);
    };

    const onFinish = async (values: Values) => {
        if (!vehicle?.id) {
            app.notification.error({
                message: 'Erro',
                description: 'Veículo não encontrado.',
            });
            return;
        }

        const response = await updateVehicle(vehicle.id, values);

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: response.message,
            description: response.description,
        });

        fetchVehicles();
        close();
    };

    return (
        <Modal
            title="Editar Veículo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editVehicle"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="apartmentId"
                    label="Apartamento"
                    rules={[{ required: true, message: 'Por favor, selecione um apartamento.' }]}
                >
                    <Select
                        disabled={apartments.length <= 0}
                        loading={isLoading}
                        showSearch
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                            setSelectedApartmentId(value);
                            form.setFieldsValue({ residentId: undefined });
                        }}
                    >
                        {apartments.map((apartment: ApartmentInterface) => (
                            <Select.Option key={apartment.id} value={apartment.id}>
                                {`${apartment.tower.name} - ${apartment.name}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="residentId"
                    label="Proprietário"
                    rules={[{ required: true, message: 'Por favor, selecione o Proprietário.' }]}
                >
                    <Select
                        loading={isLoading}
                        showSearch
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {filteredResidents.map((resident: ResidentInterface) => (
                            <Select.Option key={resident.id} value={resident.id}>
                                {`${resident.name}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Tipo"
                    rules={[{ required: true, message: 'Por favor, digite um tipo.' }]}
                >
                    <Select>
                        <Select.Option value='car'>Carro</Select.Option>
                        <Select.Option value='motobike'>Moto</Select.Option>
                        <Select.Option value='other'>Outros</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="brand"
                    label="Marca"
                    rules={[{ required: true, message: 'Por favor, digite uma marca.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="model"
                    label="Modelo"
                    rules={[{ required: true, message: 'Por favor, digite um modelo.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="board"
                    label="Placa"
                    rules={[{ required: true, message: 'Por favor, digite uma placa.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="tag"
                    label="Tag"
                >
                    <Input maxLength={500} />
                </Form.Item>

            </Form>
        </Modal>
    );
}

