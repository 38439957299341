import React, { Fragment } from 'react';

import { Button, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateGeneralMeetingModal } from 'components/GeneralMeeting/CreateGeneralMeetingModal';
import { DuplicateGeneralMeeting } from 'components/GeneralMeeting/DuplicateGeneralMeetingModal';
import { EditeGeneralMeetingModal } from 'components/GeneralMeeting/EditGeneralMeetingModal';
import { GeneralMeetingContextProvider } from 'lib/providers/GeneralMeetingContextProvider';
import { Show } from 'lib/Show';

import { COLUMNS } from './Columns';

export function GeneralMeetings() {
    return (
        <GeneralMeetingContextProvider>
            {({
                generalMeetings,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
                isDuplicateModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Enquetes
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <List
                            columns={COLUMNS}
                            data={generalMeetings}
                            loading={isLoading}
                        />
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateGeneralMeetingModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditeGeneralMeetingModal />
                    </Show>

                    <Show when={isDuplicateModalVisible}>
                        <DuplicateGeneralMeeting />
                    </Show>
                </Fragment>
            )}
        </GeneralMeetingContextProvider>
    );
}
