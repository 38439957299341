import React, { useEffect, useState } from 'react';

import { App, Breadcrumb, Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Tag, Typography } from 'antd';

import dayjs from 'dayjs';
import { getEmployeeStatus, getVacationTimeStatus } from 'lib/helpers/Employe.helper';
import { useEmployee } from 'lib/providers/EmployeeContextProvider';
import { updateEmployee, updateEmployeeVacationTime } from 'services/Employee.service';


type ValuesEmployee = {
    name: Employee.Model['name'],
    phone: Employee.Model['phone'],
    email: Employee.Model['email'],
    cpf: Employee.Model['cpf'],
    ctps: Employee.Model['ctps'],
    admission_date: Employee.Model['admission_date'],
};

type ValuesVacationTime = {
    first_time_start: Employee.VacationTime.Model['first_time_start'],
    first_time_end: Employee.VacationTime.Model['first_time_end'],
    second_time_start: Employee.VacationTime.Model['second_time_start'],
    second_time_end: Employee.VacationTime.Model['second_time_end'],
    third_time_start: Employee.VacationTime.Model['third_time_start'],
    third_time_end: Employee.VacationTime.Model['third_time_end'],
};

export function DetailEmployeeVacationTimeModal() {

    const {
        employee,
        setIsDetailModalVisible,
        setEmployeeId,
        fetchEmployees,
    } = useEmployee();

    if (!employee)
        throw new Error('Value of the `employee` property is unknown');

    const close = () => {
        setIsDetailModalVisible(false);
        setEmployeeId(null);
    };

    const app = App.useApp();

    let vacationProps;
    let employeeProps;
    
    if (employee.vacation_time_acquireds.length > 0) {
        vacationProps = getVacationTimeStatus(employee.vacation_time_acquireds[0]);
    }
    
    employeeProps = getEmployeeStatus(employee, employee.vacation_time_acquireds[0]?.vacation_times);  

    const title = (
        <Breadcrumb
            style={{ fontSize: 18, color: '#000' }}
            separator="|"
            items={[
                { title: employee.client.name },
                { title: `#${employee.id}` },
                { title: employee.name },
                { title: <Tag {...vacationProps} style={{ marginLeft: '5px', fontSize: 16 }} /> },
                { title: <Tag {...employeeProps} style={{ marginLeft: '5px', fontSize: 16 }} /> },
            ]}
        />
    );

    const [formVacationTime] = Form.useForm<ValuesVacationTime>();
    const [formEmployee] = Form.useForm<ValuesEmployee>();
    const [isSaving, setIsSaving] = useState(false);
    
    const [selectedVacationTimeAcquired, setSelectedVacationTimeAcquired] = useState<Employee.VacationTimeAcquired.Model | undefined>();
    const [usedVacationDays, setUsedVacationDays] = useState(0);
    const [, setDaysLeft] = useState(30);
    const [limitDate, setLimitDate] = useState<string | null>(null);

    useEffect(() => {
        if (!employee) return;
        const {
            name,
            phone,
            email,
            cpf,
            ctps,
            admission_date
        } = employee;
        formEmployee.setFieldsValue({
            name,
            phone,
            email,
            cpf,
            ctps,
            admission_date: admission_date ? dayjs(admission_date) : null,
        });
    }, [employee, formEmployee]);

    const onFinishEmployee = async () => {

        setIsSaving(true);

        const {
            name,
            phone,
            email,
            cpf,
            ctps,
            admission_date } = await formEmployee.validateFields();

        const response = await updateEmployee(employee.id, {
            name,
            phone,
            email,
            cpf,
            ctps,
            admission_date: admission_date ? dayjs(admission_date) : null,
        });

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: response.message,
            description: response.description,
        });

        setIsSaving(false);

        fetchEmployees();
    };


    useEffect(() => {

        if (!selectedVacationTimeAcquired) return;

        const {
            first_time_start,
            first_time_end,
            second_time_start,
            second_time_end,
            third_time_start,
            third_time_end,
        } = selectedVacationTimeAcquired.vacation_times;

        const { vacation_times, end_date } = selectedVacationTimeAcquired;
        const usedDays = calculateUsedVacationDays(vacation_times);

        setUsedVacationDays(usedDays);
        setDaysLeft(Math.max(30 - usedDays));
        setLimitDate(calculateLimitDay(usedDays, end_date));

        formVacationTime.setFieldsValue({
            first_time_start: first_time_start ? dayjs(first_time_start) : null,
            first_time_end: first_time_end ? dayjs(first_time_end) : null,
            second_time_start: second_time_start ? dayjs(second_time_start) : null,
            second_time_end: second_time_end ? dayjs(second_time_end) : null,
            third_time_start: third_time_start ? dayjs(third_time_start) : null,
            third_time_end: third_time_end ? dayjs(third_time_end) : null,
        });

    }, [selectedVacationTimeAcquired]);

    const calculateUsedVacationDays = (fieldsValue: ValuesVacationTime): number => {
        let totalUsed = 0;
    
        (Object.keys(fieldsValue) as (keyof ValuesVacationTime)[]).forEach((key) => {
            if (key.includes('_start') && fieldsValue[key]) {
                const start = dayjs(fieldsValue[key]);
                const endKey = key.replace('_start', '_end') as keyof ValuesVacationTime;
                const end = fieldsValue[endKey] ? dayjs(fieldsValue[endKey]) : null;
    
                if (start && end) {
                    totalUsed += end.diff(start, 'days') + 1;
                }
            }
        });
    
        return totalUsed;
    };

    const calculateLimitDay = (
        usedDays: number,
        periodEnd: string | null
    ): string | null => {
        if (usedDays >= 30) 
            return null;
        if (!periodEnd) 
            return null;

        const daysLeft = 30 - usedDays;
        const limit = dayjs(periodEnd).subtract(daysLeft, 'day');
        
        return limit.isValid() ? limit.format('DD/MM/YYYY') : null;
    };

    const onFinishVacationTime = async () => {
        if (!selectedVacationTimeAcquired?.id) return;

        setIsSaving(true);

        const {
            first_time_start,
            first_time_end,
            second_time_start,
            second_time_end,
            third_time_start,
            third_time_end,
        } = await formVacationTime.validateFields();

        const body = {
            first_time_start: first_time_start ? dayjs(first_time_start) : null,
            first_time_end: first_time_end ? dayjs(first_time_end) : null,
            second_time_start: second_time_start ? dayjs(second_time_start) : null,
            second_time_end: second_time_end ? dayjs(second_time_end) : null,
            third_time_start: third_time_start ? dayjs(third_time_start) : null,
            third_time_end: third_time_end ? dayjs(third_time_end) : null,
        };


        const response = await updateEmployeeVacationTime(selectedVacationTimeAcquired.vacation_times.id, {
            ...body,
            vacation_time_acquired_id: selectedVacationTimeAcquired.id,
        });

        if (response.success) {
            app.notification.success({
                message: response.message,
                description: response.description,
            });const updatedVacationTimes = { ...selectedVacationTimeAcquired.vacation_times, ...body };
            const usedDays = calculateUsedVacationDays(updatedVacationTimes);
            const daysLeft = Math.max(0, 30 - usedDays);
    
            setSelectedVacationTimeAcquired((prev) => ({
                ...prev!,
                vacation_times: updatedVacationTimes,
                days_left: daysLeft,
            }));
    
            setUsedVacationDays(usedDays);
            setDaysLeft(daysLeft);
        } else {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
        }
    
        setIsSaving(false);
        fetchEmployees();
    };

    const periodOptions = [{ index: 'first', label: 'Primeiro' }, { index: 'second', label: 'Segundo' }, { index: 'third', label: 'Terceiro' }];

    return (
        <Modal
            width='70%'
            title={title}
            onCancel={close}
            footer={null}
            open
        >
            <Row>
                <Col span={12}>
                    <Card loading={isSaving}>

                        <Typography.Title level={2} style={{ marginBottom: 16, textAlign: 'center' }}>
                            Dados do funcionário
                        </Typography.Title>

                        <Form
                            form={formEmployee}
                            name="editEmployee"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Form.Item
                                name="name"
                                label="Nome do funcionário"
                                rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                            >
                                <Input maxLength={500} onBlur={onFinishEmployee} />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="Telefone"
                            >
                                <Input maxLength={500} onBlur={onFinishEmployee} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="E-mail"
                            >
                                <Input maxLength={500} onBlur={onFinishEmployee} />
                            </Form.Item>

                            <Form.Item
                                name="cpf"
                                label="CPF"
                            >
                                <Input maxLength={500} onBlur={onFinishEmployee} />
                            </Form.Item>

                            <Form.Item
                                name="ctps"
                                label="CTPS"
                            >
                                <Input maxLength={500} onBlur={onFinishEmployee} />
                            </Form.Item>

                            <Form.Item
                                name="admission_date"
                                label="Data de admissão"
                            >
                                <DatePicker
                                    size='middle'
                                    style={{ width: '100%' }}
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={{ height: '63vh', overflow: 'auto' }}>
                        <Typography.Title level={2} style={{ marginBottom: 16, textAlign: 'center' }}>
                            Períodos de férias
                        </Typography.Title>

                        <Select
                            style={{ width: '100%', marginBottom: 16 }}
                            placeholder="Selecione um período de férias"
                            value={selectedVacationTimeAcquired?.id}
                            onChange={(value) => setSelectedVacationTimeAcquired(employee.vacation_time_acquireds.find(vca => vca.id === value))}
                        >
                            {employee.vacation_time_acquireds.map(acquired => {
                                const status = getVacationTimeStatus(acquired);
                                return (
                                    <Select.Option key={acquired.id} value={acquired.id}>
                                        {`Período Adquirido: ${dayjs(acquired.start_date).format('DD/MM/YYYY')} - ${dayjs(acquired.end_date).format('DD/MM/YYYY')}`}
                                        <Tag color={status.color} icon={status.icon} style={{ marginLeft: 8 }}>
                                            {status.children}
                                        </Tag>
                                    </Select.Option>
                                );
                            })}
                        </Select>

                        {selectedVacationTimeAcquired?.id && (
                            <>
                                <Typography.Title level={4}>Resumo</Typography.Title>
                                <p>
                                    <strong>Dias já utilizados:</strong> {usedVacationDays}
                                </p>
                                <p>
                                    <strong>Dias restantes:</strong> {selectedVacationTimeAcquired?.days_left || 0}
                                </p>

                                {limitDate && (
                                    <p>
                                        <strong>Data limite:</strong> {limitDate}
                                    </p>
                                )}
                                <Form
                                    form={formVacationTime}
                                    name="editVacationTime"
                                    layout="vertical"
                                    autoComplete="off"
                                >
                                    {periodOptions.map((period, index) => (
                                        <Row gutter={16} key={index}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name={`${period.index}_time_start`}
                                                    label={`Início do ${period.label} período`}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        size="middle"
                                                        style={{ width: '100%' }}
                                                        format="DD/MM/YYYY"
                                                        disabledDate={(current) => {
                                                            const prevEndDateKey = `${periodOptions[index - 1]?.index}_time_end`;
                                                            const prevEndDate = formVacationTime.getFieldValue(prevEndDateKey);

                                                            if (prevEndDate) {
                                                                const nextAvailableDate = dayjs(prevEndDate).add(1, 'day');
                                                                return current.isBefore(nextAvailableDate, 'day');
                                                            }
                                                            return current.isBefore(dayjs(), 'day');
                                                        }}
                                                        onChange={() =>
                                                            formVacationTime.validateFields([`${period.index}_time_end`])
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    name={`${period.index}_time_end`}
                                                    label={`Fim do ${period.label} período`}
                                                    dependencies={[`${period.index}_time_start`]}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const startDate = getFieldValue(
                                                                    `${period.index}_time_start`
                                                                );
                                                                if (startDate && !value) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'A data de fim é obrigatória quando a data de início está preenchida.'
                                                                        )
                                                                    );
                                                                }
                                                                if (startDate && value) {
                                                                    if (dayjs(value).isAfter(dayjs(startDate), 'day')) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            'A data de fim deve ser após a data de início.'
                                                                        )
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <DatePicker
                                                        size="middle"
                                                        style={{ width: '100%' }}
                                                        format="DD/MM/YYYY"
                                                        disabledDate={(current) => {
                                                            const startDate = formVacationTime.getFieldValue(
                                                                `${period.index}_time_start`
                                                            );
                                                            const usedDays = calculateUsedVacationDays(
                                                                formVacationTime.getFieldsValue()
                                                            );

                                                            if (!startDate) return true;

                                                            const maxAllowedDate = dayjs(startDate).add(
                                                                29 - usedDays,
                                                                'day'
                                                            );
                                                            return (
                                                                current.isBefore(startDate, 'day') ||
                                                                current.isAfter(maxAllowedDate, 'day')
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button type="primary" onClick={onFinishVacationTime} loading={isSaving} block>
                                        Salvar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
}
