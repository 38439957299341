import API from '../lib/API';
import { GeneralReportResident, ListResident, ValidateResident } from './contracts/ResidentServiceContract';

const route = '/resident';

export async function listResident(): Promise<Array<Apartment.Resident>> {
    const response = (await API.get(route)) as ListResident.Response;

    if (ListResident.isValidResponse(response)) {
        return response;
    }
    return [];
}

export function generalReportResident(): Promise<GeneralReportResident.Response> {
    return API.get(`${route}/general_report`);
};

export function validateResident(id: Apartment.Resident['id'], body: ValidateResident.Body): Promise<ValidateResident.Response> {
    return API.put(`${route}/${id}/validate`, body);
};