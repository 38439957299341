import React, { useState } from 'react';

import { 
    App,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Select,
    SelectProps,
} from 'antd';

import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useEmployee } from 'lib/providers/EmployeeContextProvider';
import { sleep } from 'lib/Sleep';
import { createEmployee } from 'services/Employee.service';

export type Values = {
    name: Employee.Model['name'],
    admission_date: Employee.Model['admission_date'],
    phone: Employee.Model['phone'],
    email: Employee.Model['email'],
    cpf: Employee.Model['cpf'],
    ctps: Employee.Model['ctps'],
    next_period_date: Employee.Model['next_period_date'],
    client_id: number,
};

type SelectOptions = NonNullable<SelectProps['options']>;

export function CreateEmployeeModal() {
    const [isSending, setIsSending] = useState(false);
    const { setIsCreateModalVisible, fetchEmployees } = useEmployee();
    const [isNextPeriodVisible, setIsNextPeriodVisible] = useState(false);

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => setIsCreateModalVisible(false);

    const onFinish = async ({
        name,
        admission_date,
        phone,
        email,
        cpf,
        ctps,
        next_period_date,
        client_id,}: Values) => {
        setIsSending(true);

        const formattedNextPeriodDate = next_period_date ? dayjs(next_period_date).format('YYYY-MM-DD') : null;
        
        const response = await createEmployee({
            name,
            admission_date,
            phone,
            email,
            cpf,
            ctps,
            next_period_date: formattedNextPeriodDate,
            client_id,
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: response.message,
            description: response.data.description,
        });

        fetchEmployees();
        close();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();

    const handleAdmissionDateChange = (date: any) => {
        if (date) {
            const oneYearAgo = dayjs().subtract(1, 'year');
            setIsNextPeriodVisible(date.isBefore(oneYearAgo));
        } else {
            setIsNextPeriodVisible(false);
        }
    };
    
    const disableNextPeriodDate = (currentDate: any) => {
        if (!currentDate) return false;
    
        const today = dayjs();
        const nextYear = currentDate.add(1, 'year');
    
        return nextYear.isBefore(today, 'day') || currentDate.isAfter(today);
    };

    return (
        <Modal
            title="Cadastrar funcionário"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createEmployee"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="client_id"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select options={clients} filterOption={filterOption} />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Nome do funcionário"
                    rules={[{ required: true, message: 'Por favor, digite um nome.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Telefone"
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail"
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="cpf"
                    label="CPF"
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="ctps"
                    label="CTPS"
                    rules={[{ required: true, message: 'Por favor, informe um registro da CTPS.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="admission_date"
                    label="Data da admissão"
                    rules={[{ required: true, message: 'Por favor, digite uma data de admissão.' }]}
                >
                    <DatePicker
                        size="middle"
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        onChange={handleAdmissionDateChange}
                    />
                </Form.Item>

                {isNextPeriodVisible && (
                    <>
                        <div style={{ color: 'green', marginBottom: 10 }}>
                            Para colaboradores com mais de 1 ano, por favor preencha com a data do início do período de férias.
                        </div>
                        <Form.Item
                            name="next_period_date"
                            label="Data do início do período de férias"
                            rules={[{ required: true, message: 'Por favor, informe uma data para o início do período de férias.' }]}
                        >
                            <DatePicker
                                size="middle"
                                style={{ width: '100%' }}
                                format="DD/MM/YYYY"
                                disabledDate={disableNextPeriodDate}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
}