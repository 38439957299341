import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import {
    App,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    SelectProps,
    Tag,
    Typography,
    UploadFile
} from 'antd';

import { makeClientInitialValue } from 'components/Issue/CreateIssueModal';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { DynamicSelect } from 'lib/DynamicSelect';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { createMaintenaceType, createMaintenance, listMaintenaceType, uploadMaintenanceFile } from 'services/Maintenance.service';
import { createSupplier, listSuppliers } from 'services/Supplier.service';

type SelectOptions = NonNullable<SelectProps['options']>;

export type Values = {
    clientId: Client.Model['id'],
    description: Maintenance.Model['description'],
    estimatedDate: Maintenance.Model['estimatedDate'],
    supplierId: Maintenance.Model['supplierId'] | undefined,
    maintenance_type_id: Maintenance.Model['maintenance_type_id'],
    type_of_recurrence: Maintenance.Model['type_of_recurrence'],
    recurrence: Maintenance.Model['recurrence'],
    autoSchedule: Maintenance.Model['autoSchedule'],
    type: Maintenance.Model['type'],
    files?: UploadFile[] | undefined,
};

type Body = Parameters<typeof createMaintenance>['0'];

const filterOption = makeFilterOption();

export function CreateMaintenanceModal() {
    const [type, setType] = useState<Values['type']>();
    const [maintenanceTypes, setMaintenanceTypes] = useState<Maintenance.Type[]>([]);
    const [maintenanceType, setMaintenanceType] = useState<Maintenance.Type>();
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [helpText, setHelpText] = useState<React.ReactNode>();
    const [isFinished, setIsFinished] = useState(false);

    const [form] = Form.useForm<Values>();

    const watchedClientId = Form.useWatch('clientId', form);

    const fetchData = async () => {
        if (!watchedClientId)
            return;

        setIsFetching(true);

        const response = await listSuppliers();

        if (!response.success)
            return;

        setSuppliers(response.suppliers);

        setIsFetching(false);

        return response.suppliers;
    };

    useEffect(() => {
        form.setFieldValue('supplier_id', undefined);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, watchedClientId]);

    const fetchTypes = async () => {
        setIsFetching(true);

        const response = await listMaintenaceType();

        if (!response.success)
            return;

        setMaintenanceTypes(response.maintenance_types);
        setIsFetching(false);

        return response.maintenance_types;
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    const app = App.useApp();

    const handleNewTypeOption = async (option: string) => {
        setIsFetching(true);

        const response = await createMaintenaceType({ name: option });

        await sleep(1000);

        setIsFetching(false);

        app.notification.open(response);

        const fetchedTypes = await fetchTypes();

        const newType = fetchedTypes?.find(type => type.name === option);

        form.setFieldValue('maintenance_type_id', newType?.id);
    };

    const handleNewSupplier = async (option: string) => {
        setIsFetching(true);

        // eslint-disable-next-line eqeqeq
        if (option == '') {
            setIsFetching(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva o fornecedor que deseja inserir' });
        }

        const clients = Authentication.getClients().map((client) => (client.id));

        const response = await createSupplier({ name: option, clientsToSync: clients });

        await sleep(1000);

        setIsFetching(false);

        app.notification.open(response);

        const suppliers = await fetchData();

        const newSupplier = suppliers?.find(supplier => supplier.name === option);

        form.setFieldValue('supplierId', newSupplier?.id);
    };

    const {
        setIsFinishMaintenanceModal,
        setMaintenanceId,
        setIsCreateModalVisible,
        fetchMaintenances,
        isOverviewModule,
    } = useMaintenance();

    const onFinish = async (values: Values) => {
        setIsSending(true);

        const body: Body = {
            // TODO: Get this by back-end layer to improves security
            clientId: values.clientId,

            description: values.description,
            autoSchedule: values.autoSchedule ?? 0,
            type_of_recurrence: values.type_of_recurrence,
            recurrence: values.recurrence ?? null,
            estimatedDate: values.estimatedDate,
            supplierId: values.supplierId ?? null,
            maintenance_type_id: values.maintenance_type_id,
            type: values.type
        };

        const response = await createMaintenance(body);

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles = values.files?.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
            maintenanceId: response.maintenance.id,
        })) ?? [];

        const promises = parsedFiles.map(file => uploadMaintenanceFile(file));

        const files = await Promise.all(promises);

        // Maybe we have more errors, but it'is not so important in this context
        const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

        if (firstErrorFile !== undefined)
            return handleServiceError(app, firstErrorFile);

        await fetchMaintenances();

        if (isFinished) {
            setMaintenanceId(response.maintenance.id);
            setIsFinishMaintenanceModal(true);
        }

        setIsSending(false);
        setIsCreateModalVisible(false);
    };

    const findMaintenanceType = (id: Values['maintenance_type_id']) => {
        const type = maintenanceTypes.find((t) => t.id === id);
        if (type)
            setMaintenanceType(type);
    };

    const getHelpText = () => {
        if (maintenanceType) {
            const help = <span>
                <Show when={maintenanceType.description !== '' && maintenanceType.description !== null}>
                    <Typography.Text style={{ marginTop: 5, marginBottom: 5 }} type='secondary' italic>{maintenanceType.description} </Typography.Text>
                </Show>
                <Show when={maintenanceType.norm !== '' && maintenanceType.norm !== null}>
                    <Tag style={{ marginTop: 5, fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.45)' }} color="blue">{maintenanceType.norm}</Tag>
                </Show>
                <Show when={maintenanceType.recommendation !== '' && maintenanceType.recommendation !== null}>
                    <Typography.Paragraph style={{ marginTop: 5, marginBottom: 5 }} type='secondary'>
                        <Typography.Text type='secondary' strong italic>Recomendações</Typography.Text><br />
                        <Typography.Text type='secondary' italic style={{ whiteSpace: 'pre-line' }}>{maintenanceType.recommendation}</Typography.Text>
                    </Typography.Paragraph>
                </Show>
            </span>;

            setHelpText(help);
        }
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const parsedTypes: SelectOptions = [];

    const tags = {
        'fire': {
            index: 0,
            label: 'Bombeiro e incêndio'
        },
        'employee': {
            index: 1,
            label: 'Funcionários'
        },
        'infrastructure': {
            index: 2,
            label: 'Infraestrutura'
        },
        'custom': {
            index: 3,
            label: 'Customizada'
        }
    };

    maintenanceTypes.forEach((type) => {
        if (!parsedTypes[tags[type.tag].index])
            parsedTypes[tags[type.tag].index] = {
                label: tags[type.tag].label,
                options: []
            };
        parsedTypes[tags[type.tag].index].options.push({
            label: type.name,
            value: type.id
        });
    });

    const parsedSuppliers: SelectOptions = suppliers.map(({ id, name }) => ({ value: id, label: name }));

    useEffect(() => {
        if (maintenanceType && maintenanceType?.recurrence_in_months >= 1) {
            form.setFieldValue('type', 'recurrent');
            setType('recurrent');

            form.setFieldValue('recurrence', maintenanceType?.recurrence_in_months);
            form.setFieldValue('type_of_recurrence', 'month');

            getHelpText();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenanceType]);

    const clientInitialValue = makeClientInitialValue(isOverviewModule);

    return (
        <Modal
            title="Cadastrar manutenção"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Cadastrar"
            onCancel={() => setIsCreateModalVisible(false)}
            cancelText="Cancelar"
            width={1000}
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createMaintenance"
                layout="vertical"
                autoComplete="off"
                initialValues={{ clientId: clientInitialValue }}
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<Values>
                            name="clientId"
                            label="Condomínio"
                            rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                        >
                            <Select options={clients} filterOption={filterOption} />
                        </Form.Item>

                        <Form.Item<Values>
                            name="maintenance_type_id"
                            label="Categoria"
                            rules={[{ required: true, message: 'Por favor, selecione a categoria do chamado.' }]}
                            help={helpText}
                        >
                            <DynamicSelect
                                onChange={value => findMaintenanceType(value)}
                                options={parsedTypes}
                                dropdown={{ placeholder: 'Insira aqui uma nova categoria', onNewOption: handleNewTypeOption }}
                                loading={isFetching}
                                disabled={isFetching}
                            />
                        </Form.Item>

                        <Form.Item<Values>
                            name="description"
                            label="Descreva como a manutenção deve ser realizada"
                            rules={[{ required: true, message: 'Por favor, digite uma descrição.' }]}
                            tooltip='Para garantir uma manutenção eficaz, por favor, forneça detalhes específicos sobre o local, equipamentos e quaisquer outras informações relevantes. Isso é fundamental para assegurar que o serviço seja realizado com precisão e segurança.'
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                        <Form.Item<Values>
                            name="supplierId"
                            label="Fornecedor que irá realizar a manutenção"
                            help="Você não precisa selecionar um fornecedor agora, pois você pode selecionar ao finalizar a manutenção."
                        >
                            <DynamicSelect
                                options={parsedSuppliers}
                                loading={isFetching}
                                disabled={isFetching}
                                dropdown={{ placeholder: 'Insira aqui um novo fornecedor', onNewOption: handleNewSupplier }}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="type"
                            label="A manutenção será realizada de forma recorrente?"
                            rules={[{ required: true, message: 'Selecione uma opção' }]}>
                            <Radio.Group onChange={value => setType(value.target.value)}>
                                <Radio value="recurrent" >Sim</Radio>
                                <Radio value="single" >Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Show when={type === 'recurrent'}>
                            <Form.Item
                                name="type_of_recurrence"
                                label="Tipo de recorrência"
                                rules={[{ required: type === 'recurrent', message: 'Defina o tipo de recorrência' }]}
                            >
                                <Select>
                                    <Select.Option value="day">Dias</Select.Option>
                                    <Select.Option value="week">Semanas</Select.Option>
                                    <Select.Option value="month">Meses</Select.Option>
                                    <Select.Option value="year">Anos</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="recurrence"
                                label="Defina a frequência"
                                rules={[{ required: type === 'recurrent', message: 'Defina a frequência' }]}
                                help='A recorrência pode ser definida em dias, semanas, meses ou anos; por exemplo, preencha o campo com 1 para diária, 1 para semanal, 1 para mensal, ou 1 para anual, ajustando o valor conforme necessário (e.g., 2 para a cada 2 dias, 2 para a cada 2 semanas, 3 para trimestral, 2 para bienal).'
                            >
                                <Input type='number' min={1} />
                            </Form.Item>
                        </Show>
                        <Show when={type === 'recurrent'}>
                            <Form.Item
                                style={{ paddingTop: 10, paddingBottom: 10 }}
                                name="autoSchedule"
                                label="Deseja que o sistema faça o autoagendamento?"
                                rules={[{ required: type === 'recurrent', message: 'Selecione uma opção' }]}
                                help="Dessa forma você não precisa se preocupar, ao finalizar uma manutenção recorrente o sistema irá criar automaticamente a próxima, sempre respeitando a recorrência que você configurou.">
                                <Radio.Group>
                                    <Radio value={0} >Não</Radio>
                                    <Radio value={1} >Sim</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Show>
                        <Form.Item
                            name="estimatedDate"
                            label="Qual a data estimada para realização?"
                            rules={[{ required: true, message: 'Selecione uma data' }]}
                            help='Você será notificado com no mínimo 30 dias de antecedência.'>
                            <DatePicker
                                placeholder='Selecione uma data'
                                format={'DD/MM/YYYY'}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="A manutenção já foi realizada?"
                            rules={[{ required: true, message: 'Selecione uma opção' }]}>
                            <Radio.Group value={isFinished} onChange={value => setIsFinished(value.target.value)}>
                                <Radio value={false} >Não</Radio>
                                <Radio value={true} >Sim</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <UploadField
                            name="files"
                            buttonText="Anexar documentos e imagens"
                            type="picture"
                            multiple
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
