export namespace ListResident {
    export type Data = Array<Apartment.Resident>;
    export type Response = Data | Service.ErrorResponse;

    export function isValidResponse(response: Response): response is Data {
        return Array.isArray(response);
    }
}

export namespace GeneralReportResident {
    export type Response = Service.DefaultResponse<string, 'url'>
};

export namespace ValidateResident {
    export type Body = {
        is_approval_peding: boolean,
        justification: string | null
    };

    export type Response =
        | Service.AlertResponse;
};