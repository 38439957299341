import React, { useEffect, useState } from 'react';

import { App, Button, Card, Form, Input, Select, SelectProps, UploadFile } from 'antd';

import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useClient } from 'lib/providers/ClientContextProvider';
import { ReCAPTCHA } from 'lib/ReCAPTCHA';
import { UploadField } from 'lib/UploadField';
import { createIssueByClient, listApartmentsByClient, listIssueTypesByClient } from 'services/Client.service';

import { ApartmentField } from './ApartmentField';

export type Values = {
    name: string,
    phone: string,
    email: string,
    apartment: string,
    type: Issue.Type['id'],
    description: Issue.Model['description'],
    files: UploadFile[] | undefined,
};

type SelectOptions = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function CreateIssueForm() {
    const [isApartmentsLoading, setIsApartmentsLoading] = useState(true);
    const [isTypesLoading, setIsTypesLoading] = useState(true);

    const [isSending, setIsSending] = useState(false);

    const [apartments, setApartments] = useState<Apartment.Model[]>([]);
    const [types, setTypes] = useState<Issue.Type[]>([]);

    const [token, setToken] = useState<string | null>(null);

    const [form] = Form.useForm<Values>();

    const { loadClient } = useClient();

    const client = loadClient();

    const app = App.useApp();

    useEffect(() => {
        const fetchApartments = async () => {
            setIsApartmentsLoading(true);

            const response = await listApartmentsByClient(client.id);

            if (!response.success)
                return handleServiceError(app, response);

            setIsApartmentsLoading(false);
            setApartments(response.apartments);
        };

        const fetchTypes = async () => {
            setIsTypesLoading(true);

            const response = await listIssueTypesByClient(client.id);

            if (!response.success)
                return handleServiceError(app, response);

            setIsTypesLoading(false);
            setTypes(response.issue_types);
        };

        fetchApartments();
        fetchTypes();
    }, [client.id, app]);

    const onFinish = async ({
        name,
        phone,
        email,
        apartment, 
        description,
        type,
        files = []
    }: Values) => {
        const parsedFiles = files.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
        }));

        const subject = `${name} (${apartment})`;

        const parsedDescription = [
            ['Nome:', name].join(' '),
            '\n',
            ['Telefone:', phone].join(' '),
            '\n',
            ['E-mail:', email].join(' '),
            '\n',
            ['Unidade:', apartment].join(' '),
            '\n\n',
            'Solicitação:',
            '\n\n',
            description,
        ].join('');

        const body = {
            subject,
            description: parsedDescription,
            issueTypeId: type,
            files: parsedFiles,
            external_email: email
        };

        setIsSending(true);

        const response = await createIssueByClient(client.id, body);

        app.notification.open(response);

        setIsSending(false);

        // Reset
        form.resetFields();
    };

    const parsedTypes: SelectOptions = types.map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Card title="Cadastrar chamado">
            <Form
                form={form}
                onFinish={onFinish}
                name="createIssue"
                layout="vertical"
                autoComplete="off"
            >

                <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, digite seu nome.' }]}
                >
                    <Input placeholder="Qual é o seu nome?" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Telefone"
                    rules={[{ required: true, message: 'Por favor, digite seu telefone.' }]}
                >
                    <Input placeholder="Qual é o seu telefone?" />
                </Form.Item>


                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[{ required: true, message: 'Por favor, digite seu e-mail.' }]}
                >
                    <Input placeholder="Qual é o seu e-mail?" />
                </Form.Item>

                <ApartmentField 
                    apartments={apartments} 
                    isLoading={isApartmentsLoading}
                />

                <Form.Item<Values>
                    name="type"
                    label="Categoria"
                    rules={[{ required: true, message: 'Por favor, selecione uma categoria.' }]}
                >
                    <Select
                        options={parsedTypes}
                        loading={isTypesLoading}
                        filterOption={filterOption}
                        placeholder="Qual é o tipo de chamado que você está cadastrando?"
                        showSearch
                        allowClear
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="description"
                    label="Descrição"
                    rules={[{ required: true, message: 'Por favor, digite uma descrição.' }]}
                >
                    <Input.TextArea  placeholder="O que aconteceu?" rows={4} />
                </Form.Item>

                <UploadField name='files' type='picture' buttonText='Anexar documentos ou imagens' />

                <ReCAPTCHA onChange={value => setToken(value)} />

                <Button 
                    style={{ marginTop: 24 }}
                    disabled={token === null}
                    loading={isSending}
                    type="primary"
                    htmlType="submit"
                >
                    Cadastrar
                </Button>
            </Form>
        </Card>
    );
}
