import React, { useEffect, useReducer, useState } from 'react';

import { Button, Col, Input, Row, Select, Tag } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { ApartmentInterface } from 'components/_Common/_Interfaces/Apartment';
import { ResidentInterface } from 'components/_Common/_Interfaces/Resident';
import API from 'lib/API';
import { isFilledField } from 'lib/helpers/Form.helper';
import { listResident } from 'services/ResidentService';
import { OptionalField } from 'types/packages/antd';

type Filter = {
    apartment: OptionalField<ApartmentInterface['id']>,
    name: OptionalField<Apartment.Resident['name']>,
    status: OptionalField<Apartment.Resident['type']>,
    cpf: OptionalField<Apartment.Resident['cpf']>,
    phone: OptionalField<Apartment.Resident['phone']>,
};

const FILTER_DEFAULT_VALUE: Filter = {
    name: undefined,
    apartment: undefined,
    phone: undefined,
    cpf: undefined,
    status: undefined,
};

const parsedStatus = [
    { value: 'owner', label: 'PROPRIETÁRIO' },
    { value: 'owner-resident', label: 'PROPRIETÁRIO MORADOR' },
    { value: 'resident', label: 'MORADOR' },
    { value: 'tenant', label: 'INQUILINO' },
    { value: 'approval_pending', label: 'APROVAÇÃO PENDENTE' },
    { value: 'authorized_visitor', label: 'VISITANTE AUTORIZADO' },
    { value: 'local_employee', label: 'FUNCIONÁRIO DO LOCAL' },
    { value: 'emergency_contact', label: 'CONTATO DE EMERGÊNCIA' },
    { value: 'is_in_debt', label: 'INADIMPLENTE' },
];

const getStatusColor = (status: string): string => {
    switch (status) {
    case 'approval_pending': return 'red';
    case 'is_in_debt': return 'red';
    case 'owner': return 'success';
    case 'owner-resident': return 'success';
    case 'resident': return 'processing';
    case 'tenant': return 'gold';
    case 'authorized_visitor': return 'green';
    case 'local_employee': return 'grey';
    case 'emergency_contact': return 'orange';
    default: return 'default';
    }
};

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const useResidents = () => {
    const [residents, setResidents] = useState<Apartment.Resident[]>([]);
    const [filteredResidents, setFilteredResidents] = useState<Apartment.Resident[]>([]);

    const fetchResidents = async () => {
        const response = await listResident();
        setResidents(response);
        setFilteredResidents(response);
    };

    return { residents, setResidents, filteredResidents,fetchResidents };
};

type ResidentsFilterBarProps = {
    onFilterChange: (filteredResidents: Array<Apartment.Resident>) => void;
};

export function ResidentsFilterBar({ onFilterChange }: ResidentsFilterBarProps) {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);
    const [isFiltering, setIsFiltering] = useState(false);
    const [apartments, setApartments] = useState<ApartmentInterface[]>([]);
    
    const { residents, fetchResidents } = useResidents();

    const fetchOptions = async () => {
        const result = await API.get('/apartment');
        setApartments(result);
    };

    useEffect(() => {
        fetchOptions();
        fetchResidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilter = () => {
        let filteredResidents = [...residents];

        const { 
            apartment,
            status,
            name,
            phone,
            cpf,
        } = filter;

        const normalizeCpf = (cpf: string) => {
            return cpf.replace(/\D/g, '');
        };
        
        if (isFilledField<ResidentInterface['apartmentId']>(apartment)) 
            filteredResidents = filteredResidents.filter(residents => residents.apartmentId === apartment);
        
        if (isFilledField<ResidentInterface['type']>(status)) {
            filteredResidents = filteredResidents.filter((resident) => {
                if (status === 'owner-resident') {
                    return resident.type === 'owner' && resident.isResident;
                } else if (status === 'owner') {
                    return resident.type === 'owner' && !resident.isResident;
                } else if (status === 'approval_pending') {
                    return resident.is_approval_pending;
                } else if (status === 'is_in_debt') {
                    return resident.apartment.is_in_debt;
                }
                return resident.type === status;
            });
        }
        
        if (isFilledField<ResidentInterface['name']>(name))
            filteredResidents = filteredResidents.filter(residents => residents.name?.toLowerCase().includes(name.toLowerCase()));
    
        if (isFilledField<ResidentInterface['phone']>(phone))
            filteredResidents = filteredResidents.filter(residents => residents.phone?.includes(phone));
    
        if (isFilledField<ResidentInterface['cpf']>(cpf)) {
            const normalizedCpf = normalizeCpf(cpf);
            filteredResidents = filteredResidents.filter(residents => {
                const residentCpf = normalizeCpf(residents.cpf || '');
                return residentCpf.includes(normalizedCpf);
            });
        }
        
        setIsFiltering(true);
        onFilterChange(filteredResidents);
    };

    const handleClear = async () => {
        setFilter(FILTER_DEFAULT_VALUE);
        await fetchResidents();
        setIsFiltering(false);
        onFilterChange([...residents]);
    };

    const isActionButtonDisabled = Object.values(filter).every(value =>
        value === undefined || (Array.isArray(value) && value.every(v => v === null))
    );

    return (
        <Row gutter={[8, 8]}>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    disabled={isFiltering}
                    loading={apartments.length <= 0}
                    showSearch
                    value={filter.apartment}
                    onChange={(value) => setFilter({ apartment: value })}
                    placeholder="Unidades"
                    allowClear
                    filterOption={(input, option: any) => {
                        const searchTerms = input.toLowerCase().split(/[\s-]+/);
                        const apartmentParts = option.children.toLowerCase().split(/[\s-]+/);

                        return searchTerms.every((term: string) =>
                            apartmentParts.some((part: string) => part.includes(term))
                        );
                    }}
                >
                    {apartments.map((apartment: ApartmentInterface) => (
                        <Select.Option key={apartment.id} value={apartment.id}>
                            {`${apartment.tower?.name || 'Sem Torre'} - ${apartment.name}`}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    value={filter.status}
                    onChange={value => setFilter({ status: value })}
                    onClear={() => setFilter({ status: undefined })}
                    placeholder="Status"
                    disabled={isFiltering}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                        const label = option?.label as string | undefined;
                        return label?.toLowerCase().includes(input.toLowerCase()) || false;
                    }}
                    options={parsedStatus.map(status => ({
                        value: status.value,
                        label: (
                            <Tag color={getStatusColor(status.value)}>
                                {status.label}
                            </Tag>
                        ),
                    }))}
                />
            </Col>


            <Col md={4} xs={24}>
                <Input
                    value={filter.name ?? ''}
                    onChange={e => setFilter({ name: e.target.value || '' })}
                    placeholder="Nome"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={3} xs={24}>
                <Input
                    value={filter.phone ?? ''}
                    onChange={e => setFilter({ phone: e.target.value || '' })}
                    placeholder="Contato"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={3} xs={24}>
                <Input
                    value={filter.cpf ?? ''}
                    onChange={e => setFilter({ cpf: e.target.value || '' })}
                    placeholder="Documento"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={6} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
