import API from '../lib/API';
import { CreateEmployee, CreateEmployeeVacationTime, CreateEmployeeVacationTimeAcquired, DeleteEmployee, ListEmployees, ListEmployeeVacationTimeAcquireds, ListEmployeeVacationTimes, UpdateEmployee, UpdateEmployeeVacationTime } from './contracts/Employee.contract';

const route = '/employee';

export function listEmployees(clients: Client.Model['id'][] = []): Promise<ListEmployees.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
}

export function createEmployee(body: CreateEmployee.Body): Promise<CreateEmployee.Response> {
    return API.post(route, body);
}

export function updateEmployee(id: Employee.Model['id'], body: UpdateEmployee.Body): Promise<UpdateEmployee.Response> {
    return API.put(`${route}/${id}`, body);
}

export function deleteEmployee(id: Employee.Model['id']): Promise<DeleteEmployee.Response> {
    return API.delete(`${route}/${id}`);
}

// # Employee Vacation Time
export function listEmployeeVacationTimes(): Promise<ListEmployeeVacationTimes.Response> {
    return API.get(`${route}/vacation_time_acquireds/vacation_times`);
};

export function createEmployeeVacationTime(body: CreateEmployeeVacationTime.Body): Promise<CreateEmployeeVacationTime.Response> {
    return API.post(`${route}/vacation_time_acquireds/vacation_times`, body);
}

export function updateEmployeeVacationTime(id: Employee.VacationTime.Model['id'], body: UpdateEmployeeVacationTime.Body): Promise<UpdateEmployeeVacationTime.Response> {
    return API.put(`${route}/vacation_time_acquireds/vacation_times/${id}`, body);
}

export function deleteEmployeeVacationTime(id: Employee.VacationTime.Model['id']): Promise<UpdateEmployeeVacationTime.Response> {
    return API.delete(`${route}/vacation_time_acquireds/vacation_times/${id}`);
}

// # Employee Vacation Time Acquired
export function listEmployeeVacationTimeAcquireds(clients: Client.Model['id'][] = []):  Promise<ListEmployeeVacationTimeAcquireds.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));
    
    return API.get(`${route}/vacation_time_acquireds`);
};

export function createEmployeeVacationTimeAcquired(body: CreateEmployeeVacationTimeAcquired.Body): Promise<CreateEmployeeVacationTimeAcquired.Response> {
    return API.post(`${route}/vacation_time_acquireds`, body);
}