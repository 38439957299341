import React, { useReducer, useState } from 'react';

import { Button, Col, Input, Row, Select } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { useEmployee } from 'lib/providers/EmployeeContextProvider';
import { Show } from 'lib/Show';
import { makeClientOptions } from 'pages/issue/Filters';
import type { OptionalField } from 'types/packages/antd';

type Props = {
    /** @default false */
    isOverviewModule?: boolean;
};

type Filter = {
    client: OptionalField<Employee.Model['client_id']>;
    name: OptionalField<Employee.Model['name']>;
    status: OptionalField<Employee.Status>;
};

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    client: undefined,
    name: undefined,
    status: undefined,
};

const filterOption = makeFilterOption();

export function EmployeeFilterBar({ isOverviewModule = false }: Props) {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);
    const [isFiltering, setIsFiltering] = useState(false);
    const { employees, setEmployees, fetchEmployees } = useEmployee();

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchEmployees();
    };

    const handleFilter = () => {
        let filteredEmployees = employees;

        console.log('Filtro aplicado:', filter.status); 

        const { client, name, status } = filter;

        if (isFilledField<Employee.Model['client_id']>(client)) 
            filteredEmployees = filteredEmployees.filter(employee => employee.client_id === client);

        if (isFilledField<Employee.Model['name']>(name))
            filteredEmployees = filteredEmployees.filter(employee => 
                employee.name.toLowerCase().includes(name.toLowerCase())
            );

        if (isFilledField<Employee.Status>(status)) {
            filteredEmployees = filteredEmployees.filter(employee => employee.status === status);
        }

        setIsFiltering(true);
        setEmployees(filteredEmployees);
    };

    const isActionButtonDisabled = Object.values(filter).every(value =>
        value === undefined || (Array.isArray(value) && value.every(v => v === null))
    );

    const parsedStatus = [
        { value: 'in_vacation', label: 'Em férias' },
        { value: 'working', label: 'Trabalhando' },
    ];

    return (
        <Row gutter={[8, 8]}>
            <Show when={isOverviewModule}>
                <Col md={6} xs={24}>
                    <Select
                        style={{ fontWeight: 'normal', width: '100%' }}
                        options={makeClientOptions()}
                        value={filter.client}
                        onChange={value => setFilter({ client: value })}
                        onClear={() => setFilter({ client: undefined })}
                        placeholder="Condomínio"
                        filterOption={filterOption}
                        disabled={isFiltering}
                        allowClear
                        showSearch
                    />
                </Col>
            </Show>

            <Col md={isOverviewModule ? 6 : 12} xs={24}>
                <Input
                    value={filter.name ?? undefined}
                    onChange={event => setFilter({ name: event.currentTarget.value })}
                    placeholder="Funcionário"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={6} xs={2}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedStatus}
                    value={filter.status}
                    onChange={value => setFilter({ status: value })}
                    onClear={() => setFilter({ status: undefined })}
                    placeholder="Status"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={6} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
