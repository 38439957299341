import React, { Fragment, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Card, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateEmployeeModal } from 'components/Employee/CreateEmployeeModal';
import { DetailEmployeeVacationTimeModal } from 'components/Employee/DetailEmployeeVacationTimeModal';
import { EmployeeFilterBar } from 'components/Employee/EmployeeFilterBar';
import { EmployeeContextProvider } from 'lib/providers/EmployeeContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Employees({ location }: RouteComponentProps) {
    const isOverviewModule = location.pathname.includes('/overview');

    const filteredColumns = useMemo(() => (
        !isOverviewModule
            ? Columns.filter(column => column.key !== 'clientName')
            : Columns
    ), [isOverviewModule]);

    return (
        <EmployeeContextProvider isOverviewModule={isOverviewModule}>
            {({
                employees,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isDetailModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Controle de Férias
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <Card>
                            <EmployeeFilterBar isOverviewModule={isOverviewModule} />
                            <List
                                data={employees}
                                columns={filteredColumns}
                                loading={isLoading} />
                        </Card>
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateEmployeeModal />
                    </Show>

                    <Show when={isDetailModalVisible}>
                        <DetailEmployeeVacationTimeModal />
                    </Show>
                </Fragment>
            )}
        </EmployeeContextProvider>
    );
}
