import API from '../lib/API';
import { CreateConsumption, CreateConsumptionReading, DeleteConsumptionReading, FindLastConsumptionReading, ListConsumption, ListConsumptionReading, ListTypeConsumption } from './contracts/Consumption.contract';

export const listTypeConsumption = (): Promise<ListTypeConsumption.Response> => {
    return API.get('/type_consumption');
};

export const listConsumption = (): Promise<ListConsumption.Response> => {
    return API.get('/consumption');
};

export const listConsumptionReading = (): Promise<ListConsumptionReading.Response> => {
    return API.get('/consumption_reading');
};

export const deleteConsumptionReading = (id: Consumption.Reading['id']): Promise<DeleteConsumptionReading.Response> => {
    return API.delete(`/consumption_reading/${id}`);
};

export const findLastConsumptionReading = (consumption_id: Consumption.Model['id']): Promise<FindLastConsumptionReading.Response> => {
    return API.get(`/consumption_reading/${consumption_id}`);
};

export const findLastConsumptionReadingByApartment = (type_consumption_id: Consumption.Model['type_consumption_id'], apartment_id: Apartment.Model['id']): Promise<FindLastConsumptionReading.Response> => {
    return API.get(`/consumption_reading/${type_consumption_id}/apartment/${apartment_id}`);
};

export const createConsumption = (body: CreateConsumption.Body): Promise<CreateConsumption.Response> => {
    return API.post('/consumption', body);
};

export const createConsumptionReading = (body: CreateConsumptionReading.Body): Promise<CreateConsumptionReading.Response> => {
    return API.post('/consumption_reading', body);
};