import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listEmployees } from 'services/Employee.service';

export type LoadedEmployee = Employee.With<'client' | 'client_manager' | 'vacation_time_acquireds'>;

type Value = {
    isLoading: boolean,
    employees: LoadedEmployee[],
    employee: LoadedEmployee | null,
    employeeId: Employee.Model['id'] | null,
    setEmployees: Utility.SetState<Value['employees']>,
    setEmployeeId: Utility.SetState<Value['employeeId']>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Utility.SetState<Value['isCreateModalVisible']>,
    isEditModalVisible: boolean,
    isDetailModalVisible: boolean,
    setIsDetailModalVisible: Utility.SetState<Value['isDetailModalVisible']>,
    setIsEditModalVisible: Utility.SetState<Value['isEditModalVisible']>,
    isOverviewModule: boolean,
    fetchEmployees: () => Promise<void>,
};

type Props = { 
    isOverviewModule: boolean,
    children: (value: Value) => ReactNode,
};

const EmployeeContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function EmployeeContextProvider({ isOverviewModule, children }: Props) {
    const [isLoading, setIsLoading] = useState(false);

    const [employees, setEmployees] = useState<Value['employees']>([]);
    const [employeeId, setEmployeeId] = useState<Value['employeeId']>(null);

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(() => employeeId !== null);


    const app = App.useApp();

    const fetchEmployees = async () => {
        setIsLoading(true);
        
        const clients = isOverviewModule
            ? Authentication.getClients().map(client => client.id)
            : [Authentication.getCurrentClientId()].filter(Boolean);

        const response = await listEmployees(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setEmployees(response.employees);
    };

    const memoizedFetchEmployees = useCallback(fetchEmployees, [isOverviewModule, app]);

    useEffect(() => {
        memoizedFetchEmployees();
    }, [memoizedFetchEmployees]);

    const employee = useMemo(() => {
        if (!employeeId)
            return null;

        const found = employees.find(employee => employee.id === employeeId);

        if (!found)
            throw new Error(`Could not find a employee with id ${employeeId}`);

        return found;
    }, [employees, employeeId]);

    const value: Value = {
        isLoading,
        employees,
        employee,
        employeeId,
        setEmployees,
        setEmployeeId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        isDetailModalVisible,
        setIsEditModalVisible,
        setIsDetailModalVisible,
        isOverviewModule,
        fetchEmployees: memoizedFetchEmployees,
    };

    return (
        <EmployeeContext.Provider value={value}>
            {children(value)}
        </EmployeeContext.Provider>
    );
}

export function useEmployee() {
    const context = useContext(EmployeeContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `EmployeeContextProvider`.');

    return context;
}
