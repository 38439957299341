import API from '../lib/API';
import {
    CreateIssue,
    CreateIssueFollowUp,
    CreateIssueType,
    DeleteIssue,
    DeleteIssueFollowUp,
    FindIssue,
    FinishIssue,
    FinishTask,
    GenerateDetailedReportIssuesById,
    GenerateReportIssue,
    GetIssueEligibleResponsibleUsers,
    GetTotalIssueByType,
    GetTotalIssuePerMonth,
    LinkedTasksDisapproved,
    ListFollowUpByIssueId,
    ListIssue,
    ListIssueType,
    ListPendingIssuesByClientManager,
    ReopenIssue,
    ShareIssueToSupplier,
    ShareIssueWithResident,
    UpdateIssue,
    UploadIssueFile,
} from './contracts/Issue.contract';

const route = '/issue';

export function createIssue(body: CreateIssue.Body): Promise<CreateIssue.Response> {
    return API.post(route, body);
};

export function finishTask(body: FinishTask.Body): Promise<FinishTask.Response> {
    return API.put(`${route}/finish-task/${body.id}`, body);
};

export function linkedTasksDisapproved(body: LinkedTasksDisapproved.Body): Promise<LinkedTasksDisapproved.Response> {
    return API.post(`${route}/linked-tasks-disapproved`, body);
};

export function updateIssue(id: Issue.Model['id'], body: UpdateIssue.Body): Promise<UpdateIssue.Response> {
    return API.put(`${route}/${id}`, body);
};

export function finishIssue(id: Issue.Model['id'], body: FinishIssue.Body): Promise<FinishIssue.Response> {
    return API.put(`${route}/${id}`, body);
};

export function uploadIssueFile(body: UploadIssueFile.Body): Promise<UploadIssueFile.Response> {
    return API.post('/default/issueFile', body);
};

export function uploadTaskFile(body: FinishTask.BodyTaskFile): Promise<FinishTask.Response> {
    return API.post('/default/IssueChecklistTaskFile', body);
};

export function listIssue(clients: Client.Model['id'][] = []): Promise<ListIssue.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
};

export function listPendingIssuesByClientManagar(): Promise<ListPendingIssuesByClientManager.Response> {
    return API.get(`${route}/pending/client-manager`);
}

export function shareIssueWithResident(id: Issue.Model['id'], body: ShareIssueWithResident.Body): Promise<ShareIssueWithResident.Response> {
    return API.put(`${route}/share/${id}`, body);
};

export function listIssueType(): Promise<ListIssueType.Response> {
    return API.get('/issue_type');
};

export function createIssueType(body: CreateIssueType.Body): Promise<CreateIssueType.Response> {
    const route = '/issue_type';

    return API.post(route, body);
};

export function findIssue(id: Issue.Model['id']): Promise<FindIssue.Response> {
    return API.get(`${route}/${id}`);
};

export function listFollowUpByIssueId(id: Issue.Model['id']): Promise<ListFollowUpByIssueId.Response> {
    return API.get(`/issue_follow_up/${id}`);
};

export function deleteIssueFollowUp(id: Issue.IssueFollowUp['id']): Promise<DeleteIssueFollowUp.Response> {
    return API.delete(`/issue_follow_up/${id}`);
};

export function createIssueFollowUp(body: CreateIssueFollowUp.Body): Promise<CreateIssueFollowUp.Response> {
    return API.post('/issue_follow_up', body);
};

export function uploadFile(body: CreateIssueFollowUp.BodyFile): Promise<CreateIssueFollowUp.Response> {
    return API.post('/default/IssueFollowUpFile', body);
};

export function getTotalIssueByType(): Promise<GetTotalIssueByType.Response> {
    return API.get(`${route}/type`);
};

export function getTotalIssuePerMonth(): Promise<GetTotalIssuePerMonth.Response> {
    return API.get(`${route}/month`);
};

/**
 * @route `issue/eligible-responsible-users?client_id=7
 * @route `issue/eligible-responsible-users
 */
export function getIssueEligibleResponsibleUsers(clientId?: Client.Model['id']): Promise<GetIssueEligibleResponsibleUsers.Response> {
    if (clientId === undefined)
        return API.get(`${route}/eligble-responsible-users`);

    const params = new URLSearchParams({ client_id: clientId.toString() });

    return API.get(`${route}/eligble-responsible-users?${params.toString()}`);
}

export function shareIssueToSupplier(id: Issue.Model['id']): Promise<ShareIssueToSupplier.Response> {
    return API.post(`${route}/share-to-supplier/${id}`);
}

export function deleteIssue(id: Issue.Model['id']): Promise<DeleteIssue.Response> {
    return API.delete(`${route}/${id}`);
};

export function reopenIssue(id: Issue.Model['id']): Promise<ReopenIssue.Response> {
    return API.put(`${route}/reopen/${id}`);
};

export function generateReportIssue(body: GenerateReportIssue.Body): Promise<GenerateReportIssue.Response> {
    return API.post(`${route}/generate_report`, body);
};

export function generateDetailedReportIssuesById(id: Issue.Model['id']): Promise<GenerateDetailedReportIssuesById.Response> {
    return API.get(`/issue/report/${id}`);
};